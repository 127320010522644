import { COLORS } from '@constants/colors';
import { SanteAcademieIcon } from '@assets/svg/SanteAcademieIcon';
import { SIZES } from '@constants/sizes';
import useWindow from '@hooks/useWindow';
import bubbleGroup from '@assets/images/bubble-group.png';
import phonePlaceholder from '@assets/images/phone-placeholder.png';

import * as S from './styled';

export enum PasswordInputEnum {
    TEXT = 'text',
    PASSWORD = 'password',
}

interface IAuthLayout {
    children: React.ReactNode;
}

const AuthLayout = ({ children }: IAuthLayout) => {
    const { windowSize } = useWindow();

    return (
        <S.AuthContainer>
            <SanteAcademieIcon fill={COLORS.blueDarker} className="sa-icon" />
            <S.LeftPanelContainer>
                <S.FormContainer>{children}</S.FormContainer>
            </S.LeftPanelContainer>
            {windowSize.width > SIZES.tablet && (
                <S.PresentationContainer>
                    <S.BubbleImage src={bubbleGroup} />
                    <S.Video autoPlay muted loop>
                        <source
                            src="https://assets.website-files.com/5fa997afa489c5171404c70c/5fea4ae7d2c3b31a90256bbc_sante-academie_ui_animation_screen_v06 (1)-transcode.mp4"
                            data-wf-ignore="true"
                        />
                    </S.Video>
                    <S.PhonePlaceholder src={phonePlaceholder} />
                </S.PresentationContainer>
            )}
        </S.AuthContainer>
    );
};

export default AuthLayout;
