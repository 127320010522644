import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

const borderWidth = 8;

export const AuthContainer = styled.div`
    width: calc(100vw - ${borderWidth}px);
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-left: ${borderWidth}px solid ${COLORS.pink};
    background-color: ${COLORS.white};

    .sa-icon {
        position: absolute;
        margin: 12px;
    }
`;

export const LeftPanelContainer = styled.div`
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 70%;
`;

export const PresentationContainer = styled.div`
    display: flex;
    flex: 3;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.yellowLighter};
    user-select: none;
`;

export const BubbleImage = styled.img`
    width: 50%;
`;

export const PhonePlaceholder = styled.img`
    position: absolute;
    width: 20%;
    transform: translateY(-4px);
`;

export const Video = styled.video`
    position: absolute;
    width: calc(13% + 1px);
`;

export const AuthForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ${SCREENS.tabletAndLower} {
        width: 100%;
    }
`;

export const AuthInput = styled.input`
    width: 100%;
    height: 40px;
    resize: none;
    border-radius: 15px;
    border: 1px solid ${COLORS.opacify(COLORS.grey, 0.5)};
    box-sizing: border-box;
    outline: none;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 150%;
    padding: 0 10px;

    ${SCREENS.tabletAndLower} {
        height: 35px;
    }
`;

export const AuthFormSuccess = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${COLORS.opacify(COLORS.turquoise, 0.2)};
    color: ${COLORS.turquoise};
    margin: 0 0 20px 0;
    padding: 10px 20px;
    font-size: 12px;
    align-self: stretch;
    font-weight: bold;
    border-radius: 8px;
`;

export const AuthFormError = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${COLORS.opacify(COLORS.pink, 0.2)};
    color: ${COLORS.pink};
    margin: 0 0 20px 0;
    padding: 10px 20px;
    font-size: 12px;
    align-self: stretch;
    font-weight: bold;
    border-radius: 8px;
`;

export const AuthButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    ${SCREENS.tabletAndLower} {
        width: auto;
        padding: 8px 30px;
        font-size: 12px;
        margin-top: 10px;
    }
`;

export const InputContainer = styled.div`
    position: relative;
    margin-bottom: 20px;

    ${SCREENS.tabletAndLower} {
        margin-bottom: 10px;
    }
`;

export const EyeContainer = styled.div`
    position: absolute;
    right: 19px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;
